import './SideBar.scss';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import { AdminPanelSettings, Dashboard } from '@/components/icons/Icons';
import {
  ComponentType,
  ReactElement,
  useCallback,
  MouseEvent,
  useState,
} from 'react';
import { useNavigationCtx } from '@/components/NavigationProvider';
import {
  GetUserPermissionsResponse,
  useUserPermissions,
} from '@/hooks/useUserPermissions';
import {
  useProjects,
  getAvailableAppsForClient,
  GetProjectsResponse,
  App,
} from '@smartfm/container/hooks/useProjects';
import { Stack } from '@smartfm/ui/components/Stack';
import { getAppLinkForClient, getUniqueObjects } from '@/utils/functions';
import { SideNavigation } from '@smartfm/container/components/SideNavigation/SideNavigation';

interface IEmeraldSideNavItem {
  label: string;
  link?: string;
  linkTarget?: string;
  linkComponent?: ComponentType<any>;
  icon?: string;
  iconComponent?: ReactElement;
  children?: IEmeraldSideNavItem[];
  separator?: boolean;
  active?: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
}

const appHeaderHeight = 48;
const minimizedWidth = 56;
const maximizedWidth = 300;

export default function SideBar() {
  const navigationCtx = useNavigationCtx();
  const project_nexus_id =
    navigationCtx?.state?.selectedOption?.project_nexus_id;
  const nexusId = navigationCtx?.state?.selectedOption?.nexusId;

  // If "project_nexus_id" is available,
  // use it as the "clientId" for the selected building.
  // If "project_nexus_id" is not available,
  // assume it's the All Buildings View and use "nexusId" as the "clientId".
  const clientId = project_nexus_id || nexusId;

  const [isPinned, setIsPinned] = useState(false);

  const userPermissionsQuery = useUserPermissions(
    useCallback((data: GetUserPermissionsResponse) => {
      if (data.isAdmin) {
        localStorage.setItem('is-admin', JSON.stringify(+data.isAdmin));
      } else {
        localStorage.removeItem('is-admin');
      }

      return data;
    }, [])
  );
  const applicationListQuery = useProjects(
    useCallback(
      (data: GetProjectsResponse): IEmeraldSideNavItem[] => {
        try {
          let availableApps: App[] = [];

          if (clientId) {
            availableApps = getAvailableAppsForClient(data, clientId);
          } else {
            const duplicatedApps =
              data.data?.map(client => client.apps).flat() ?? [];
            availableApps = getUniqueObjects(duplicatedApps, 'application_id');
          }

          const appsData: IEmeraldSideNavItem[] = availableApps.map(app => {
            const link = clientId
              ? getAppLinkForClient(`/${app.nav_path}`, clientId)
              : `/${app.nav_path}`;

            return {
              label: app.application_name,
              link,
              iconComponent: (
                <span className='material-symbols-outlined'>
                  {app.icon_url}
                </span>
              ),
            };
          });
          appsData.sort((a, b) => a.label.localeCompare(b.label));

          // Dashboard
          appsData.splice(0, 0, {
            label: 'Dashboard',
            link: getAppLinkForClient('/', clientId),
            iconComponent: <Dashboard />,
          });

          // Admin
          if (
            userPermissionsQuery.data &&
            userPermissionsQuery.data.isAdmin === true
          ) {
            appsData.push({
              label: 'Administration',
              link: '/ui/admin',
              iconComponent: <AdminPanelSettings />,
              separator: true,
            });
          }

          return appsData;
        } catch (e) {
          console.error(e);
          return [];
        }
      },
      [clientId, userPermissionsQuery.data]
    )
  );

  // Wait when clientId will be loaded
  const isLoading = applicationListQuery.isLoading;

  return (
    <Box
      sx={{
        // Correct position and height according to header
        position: 'relative',
        top: `${appHeaderHeight}px`,
        height: `calc(100% - ${appHeaderHeight}px)`,
        // SideNavigation has own box-shadow so don't show it after loading
        boxShadow: isLoading
          ? '0px 3px 8px 0px var(--eds-sn-color-shadow)'
          : 'none',
        zIndex: 100,
        width: `${isPinned ? maximizedWidth : minimizedWidth}px`,
        flexShrink: 0,
      }}
    >
      {isLoading && (
        <Stack
          justifyContent='center'
          alignItems='center'
          sx={{
            width: '3.5rem',
            height: '100%',
            backgroundColor: 'white',
          }}
        >
          <CircularProgress size='1rem' />
        </Stack>
      )}
      {!isLoading && applicationListQuery.data && (
        // This box using to implement overlaping on the page content
        <Box
          sx={{
            position: 'absolute',
            height: `100%`,
          }}
        >
          <SideNavigation
            items={applicationListQuery.data}
            expandOnHover={true}
            linkComponent={Link}
            pinExpansion={isPinned}
            onPinChange={setIsPinned}
          />
        </Box>
      )}
    </Box>
  );
}
