import { handleError, userFriendlyErrorMessage } from './utils';
import { createTheme, ThemeProvider } from '@mui/material';
import { ErrorFallback, theme } from '@smartfm/react-components';
import { ComponentType, ReactNode, useCallback } from 'react';
import { FallbackProps, ErrorBoundary } from 'react-error-boundary';

export function ErrorBoundaryWithTheme({
  children,
  isTopLevelBoundary = false,
}: {
  children: ReactNode;
  isTopLevelBoundary: boolean;
}) {
  const _FallbackComponent: ComponentType<FallbackProps> = useCallback(
    props => {
      const _userFriendlyErrorMessage = userFriendlyErrorMessage(props.error);
      return _userFriendlyErrorMessage ? (
        <ErrorFallback
          userFriendlyErrorMessage={_userFriendlyErrorMessage}
          isGoToHomeButtonHidden={isTopLevelBoundary}
        />
      ) : (
        <ErrorFallback isGoToHomeButtonHidden={isTopLevelBoundary} />
      );
    },
    [isTopLevelBoundary]
  );
  return (
    <ThemeProvider theme={createTheme(theme)}>
      <ErrorBoundary
        FallbackComponent={_FallbackComponent}
        onError={handleError}
      >
        {children}
      </ErrorBoundary>
    </ThemeProvider>
  );
}
