import { ErrorBoundaryProps } from 'react-error-boundary';

export const handleError: ErrorBoundaryProps['onError'] = (error, info) => {
  const renderingError = new Error(error.message);
  renderingError.name = `FE_logs_ExceptionFallback: ${renderingError.name}`;
  if (info.componentStack !== null) renderingError.stack = info.componentStack;
  // @ts-expect-error Property 'cause' does not exist on type 'Error'
  renderingError.cause = error;
  // eslint-disable-next-line no-console
  console.error(renderingError);
};

export function userFriendlyErrorMessage(error: Error) {
  if (typeof error === 'string') return error;
  else if (error instanceof Error) return error.message;
  return null;
}

export function throwUsingLocalStorageTestConfig() {
  const localStorageTestConfig = window.localStorage.getItem('test-config');
  if (localStorageTestConfig) {
    const config = JSON.parse(localStorageTestConfig);
    if (
      config['container-ui'] &&
      config['container-ui']['testUnhandledException']
    ) {
      throw new Error(config['container-ui']['testUnhandledException']);
    }
  }
}
